import { load } from 'recaptcha-v3';

const RECAPTCHA_SITE_KEY = '6LeYj_ElAAAAAAFeI666dgCL1viQad5KR6waIUlW';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');

        if (!form) {
            return;
        }

        const submit = form.querySelector('[type="submit"]');

        function handleSubmit(e) {
            if (window.recaptcha === null) {
                return;
            }

            // newsletter and download forms are ajax - we handle these separately
            if (form.id === 'newsletterForm' || form.id === 'downloadForm' || form.id === 'sweepsForm') {
                return;
            }

            e.preventDefault();

            submit.disabled = true;
            submit.value = 'Processing...';

            // Get reCAPTCHA score token
            window.recaptcha.execute('form').then(token => {
                // Create hidden input in form to pass reCAPTCHA score token to server
                const input = document.createElement('input');
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', 'token');
                input.setAttribute('value', token);

                form.appendChild(input);
                form.submit();
            });
        }

        // Add event handlers
        // Load reCAPTCHA object from key and bind to window
        load(RECAPTCHA_SITE_KEY).then(r => {
            window.recaptcha = r;

            form.addEventListener('submit', handleSubmit);
        });
    }
}
